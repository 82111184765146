import React, { useEffect } from 'react'
import Layout from "../components/Layout"
import Seo from "../components/seo"
import _map from 'lodash/map'
import { graphql } from 'gatsby'

const CategoryViewTemplate = (props) => {
  const { pageContext, data } = props
  const { category } = pageContext
  const [clientData, setClientData] = React.useState({})

  useEffect(() => {
    const root = {}
    data.allFile.nodes.forEach(file => {
      if (file.relativeDirectory){
        const relativePath = file.relativeDirectory.split("/")
        const client = relativePath[1]
        root[client] = root[client] || []
        root[client].push(file.publicURL)
      }
    })
    setClientData(root)
  }, [data])
  
  return (
    <Layout>
    <Seo title={category + " - Kitchen and Bathroom"} />
    <h1 className="has-text-centered title is-1 has-text-info">{category}</h1>   
    {/* <div className='container subtitle' style={{whiteSpace: "pre-line"}}>{clientData?.text}</div>  Premium hero */}
    <div className="columns is-centered is-multiline" style={{ alignItems: 'flex-end'}}>
      {_map(clientData, (data, brand) => (
        <a key={brand} className="column is-4" href={`/clients/${brand.replace(/\s+/g, '-').toLowerCase()}/${category.replace(/\s+/g, '-').toLowerCase()}`}>
          <div className='has-text-centered'>
            {data.length > 0 && <img src={data[0]} alt={brand} />}
            <span className='subtitle'>{brand}</span>
          </div>
        </a>
      ))}
    </div>
  </Layout>
  )
}

export default CategoryViewTemplate

export const pageQuery = graphql`
  query CategoryData($clientregex: String!) {
    allFile(filter: {relativeDirectory: {regex: $clientregex}}) {
      nodes {
        name
        relativeDirectory
        publicURL
      }
    }
  }
`